import { Button, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getQualityAssuranceInformation } from "../../../services/MyProfileService";
import classes from "./QualityAssuranceInfoTab.module.css";

//Plan Details End
const dataObj = {
  qaContact: {
    name: "Primary contact for QA",
    required: [3],
  },
  qaEmail: {
    name: "Email",
    required: [3],
  },
  qaLab: {
    name: "Labs you work with",
    required: [3],
  },
  qaLabName: { name: "Added Lab", required: [3] },
  sourcingPartners: { name: "Sourcing Partners", required: [3] },
};

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (text) => <span className={classes.dataheading}>{text}</span>,
  },
  {
    title: "",
    dataIndex: "data",
    key: "data",
    align: "right",
    render: (text) => <span className={classes.dataContent}>{text}</span>,
  },
];

const QualityAssuranceInfoTab = (props) => {
  const navigate = useNavigate();
  const [infoData, setInfoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  async function fetchData() {
    try {
      const res = await getQualityAssuranceInformation({
        userId: params.supplierId,
      });
      // if (res && res.data) {
      const data = res.data || {};
      console.log("data: ", data);

      const orderedKeys = Object.keys(dataObj);
      const userPlan = parseInt(localStorage.getItem("subscription"), 10) || 1;

      const infoData = orderedKeys.map((key) => {
        const value = data[key];
        const item = dataObj[key];

        let mappedName = item?.name;
        let displayValue =
          value === null ||
          value === "" ||
          (Array.isArray(value) && value.length === 0)
            ? "Field not entered by profile owner"
            : value;

        if (key === "qaLab") {
          if (value == 1) {
            displayValue = "BV";
          } else if (value == 2) {
            displayValue = "Intertek";
          }
        }

        const isAllowed = item.required.includes(userPlan);
        return {
          name: mappedName,
          data: isAllowed ? displayValue : null,
          isAllowed,
        };
      });

      setInfoData(infoData);
      // }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [props.currentPlan]);

  const SkellotonCtrl = (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );

  const tableData = infoData.map((item) => {
    if (!item.isAllowed) {
      return {
        name: item.name,
        data: (
          <span>
            <p>Please Upgrade Plan to View this Information</p>
            <Button
              type="primary"
              onClick={() => {
                navigate("/app/settings");
              }}
            >
              Subscribe Now
            </Button>
          </span>
        ),
      };
    }

    return {
      name: item.name,
      data: item.data,
    };
  });

  return loading ? (
    SkellotonCtrl
  ) : (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      showHeader={false}
      bordered={false}
    />
  );
};

export default QualityAssuranceInfoTab;
