import { Button, message, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCompanyInformation } from "../../../services/MyProfileService";
import classes from "./CompanyInfoTab.module.css";

const dataObj = {
  companyName: { name: "Company Name", required: [1, 2, 3] },
  categoryId: { name: "Department Name", required: [1, 2, 3] },
  companyLocation: { name: "Company Location", required: [1, 2, 3] },
  annualSale: { name: "Annual Sale", required: [1, 2, 3] },
  businessType: { name: "Retailer Business Type", required: [1, 2, 3] },
  companyAddress: { name: "Company Address", required: [2, 3] },
  companyWebsite: { name: "Company Website", required: [2, 3] },
  companyType: { name: "Company Type", required: [2, 3] },
  ceoName: { name: "CEO Name", required: [2, 3] },
  annualRevenue: { name: "Annual Revenue", required: [2, 3] },
  noOfLocations: { name: "No of Locations", required: [2, 3] },
  companyPhone: { name: "Company Phone", required: [3] },
  companyEmail: { name: "Company Email", required: [3] },
  noOfEmployee: { name: "No of Employees", required: [3] },
  keyCompetitors: { name: "Key Competitors", required: [3] },
};

const companyType = {
  1: "LLC",
  2: "Public",
  3: "Partnership",
  4: "Sole proprietor",
};

const businessType = {
  1: "Discount Chain",
  2: "General-Department Store",
  3: "Grocery",
  4: "Convenient Store",
  5: "Pet Supplies",
  6: "Other",
};

const CompanyInfoTab = (props) => {
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState([]);
  const generalData = useSelector((state) => state.generalData);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  async function fetchData() {
    try {
      setLoading(true);
      const res = await getCompanyInformation({ userId: params.supplierId });
      // if (res && res.data) {
      const data = res.data || {};

      const infoData = Object.keys(dataObj).map((key) => {
        const value = data[key];
        const item = dataObj[key];
        const userPlan =
          parseInt(localStorage.getItem("subscription"), 10) || 1;

        let mappedName = item?.name;
        let displayValue =
          value === null ? "Field not entered by profile owner" : value;

        // Process specific fields
        if (key === "categoryId") {
          displayValue =
            generalData?.categories?.find((obj) => obj.id == value)?.name ||
            "Field not entered by profile owner";
        } else if (key === "companyType") {
          displayValue =
            companyType[value] || "Field not entered by profile owner";
        } else if (key === "businessType") {
          displayValue =
            businessType[value] || "Field not entered by profile owner";
        }

        const isAllowed = item.required.includes(userPlan);

        return {
          name: mappedName,
          data: isAllowed ? displayValue : null,
          isAllowed,
        };
      });

      setCompanyDetails(infoData);
      // }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error("An error occurred while fetching data.");
    }
  }

  useEffect(() => {
    fetchData();
  }, [generalData, props.currentPlan]);

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  // if (companyDetails.length === 0) {
  //   return <Empty description="No Data Found" />;
  // }

  const tableData = companyDetails.map((item) => {
    if (!item.isAllowed) {
      return {
        name: item.name,
        data: (
          <span>
            <p>Please Upgrade Plan to View this Information</p>
            <Button type="primary" onClick={() => navigate("/app/settings")}>
              Subscribe Now
            </Button>
          </span>
        ),
      };
    }

    return {
      name: item.name,
      data: item.data,
    };
  });

  return (
    <Table
      columns={[
        {
          title: "",
          dataIndex: "name",
          key: "name",
          render: (text) => <span className={classes.dataheading}>{text}</span>,
        },
        {
          title: "",
          dataIndex: "data",
          key: "data",
          align: "right",
          render: (text) => <span className={classes.dataContent}>{text}</span>,
        },
      ]}
      dataSource={tableData}
      pagination={false}
      showHeader={false}
      bordered={false}
    />
  );
};

export default CompanyInfoTab;
