import axios from "axios";
import APIConstant from "../constant/APIConstant";
import Request from "./UtilsService";

export async function GetAllDepartment(data) {
  return await Request({
    url: APIConstant.DEPARTMENT_BASE_URL + APIConstant.GET_ALL_DEPARTMENT,
    method: "GET",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function GetAllCategory(data) {
  return await Request({
    url: APIConstant.CATEGORY_BASE_URL + APIConstant.GET_ALL_CATEGORY,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function GetAllCategoryByDepartmentId(data) {
  return await Request({
    url:
      APIConstant.CATEGORY_BASE_URL +
      APIConstant.GET_ALL_CATEGORY_OF_DEPARTMENT,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getDepartmentByID(id) {
  return await Request({
    url:
      APIConstant.DEPARTMENT_BASE_URL +
      APIConstant.GET_DEPARTMENT_BY_ID +
      "?departmentId=" +
      id,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getUserBasicInformation() {
  return await Request({
    url: APIConstant.USER_BASE_URL,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

const RequestPublic = async (options) => {
  const BASE_URL = process.env.REACT_APP_JAVA_APP_API_PATH;

  const config = {
    headers: options["headers"],
    url: BASE_URL + options["url"],
    method: options["method"],
    data: options["body"],
  };

  return axios
    .request(config)
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        return error;
      } else {
        return error.response.data;
      }
    });
};

export async function getStaticPage(data) {
  return await RequestPublic({
    url: APIConstant.ADMIN_BASE_URL + APIConstant.GET_ALL_STATIC_PAGE,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getUserPlan(data) {
  return await Request({
    url: "/subscriptions/verify",
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
