import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getBannerByLocation } from "../../../services/LandingPageService";
import classes from "./BannerCarasoul.module.css";
const BannerCarousal = () => {
  //API Integration start
  const [carousalItems, setCarousalItems] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: carousalItems.length > 3.0 ? 3.0 : carousalItems.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: carousalItems.length > 2.0 ? 2.0 : carousalItems.length,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: carousalItems.length > 1.0 ? 1.0 : carousalItems.length,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: carousalItems.length > 1 ? 1 : carousalItems.length,
        },
      },
    ],
  };

  const getData = async () => {
    try {
      let bannerDataObj = { location: "LOGGED_IN_LANDING_BANNER" };
      if (!localStorage.getItem("token")) {
        bannerDataObj.location = "GUEST_LANDING_BANNER";
      }
      const res = await getBannerByLocation(bannerDataObj);

      if (res && res.data.content) {
        setCarousalItems(res.data.content);
      } else {
        setCarousalItems([]);
      }
    } catch (error) {
      setCarousalItems([]);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  //API Integration end

  const carousal = carousalItems.map((item, index) => {
    const banner = item.bannerPath.split(" ").join("%20");
    return (
      <div
      className={classes.animationDiv}
        style={{
          margin: "10px",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
        }}
        key={`carousal-list-${item.title}-${index}`}
      >
        <Row
          className={
            index % 2 === 0 ? classes.bannerMainDiv1 : classes.bannerMainDiv2
          }
          style={{
            backgroundImage: `url(${banner})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            paddingBottom: "43%",
            position: "relative",
          }}
        >
          <Col xs={20} sm={20} md={20} lg={20} offset={1}>
            <h3 className={classes.title}>{item.title}</h3>
            <p className={classes.content}>{item.description}</p>
            <div>
              <Link to={item.redirectUrl} target="_blank">
                <Button
                  className={
                    index % 2 === 0
                      ? classes.btnExploreMore1 + " " + classes["btn"]
                      : classes.btnExploreMore2 + " " + classes["btn"]
                  }
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    marginBottom: "8px",
                    display: "none",
                  }}
                >
                  Explore More
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  return <Slider {...settings}>{carousal}</Slider>;
};
export default BannerCarousal;
