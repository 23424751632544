import { DownloadOutlined } from "@ant-design/icons";
import { Button, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCategoryAndProductInformation } from "../../../services/MyProfileService";
import classes from "./CategoryProductInfoTab.module.css";

const handleDownload = (fileUrl) => {
  window.open(fileUrl, "_blank");
};

const avgTimeRequired = {
  1: "30 Days",
  2: "60 Days",
  3: "90 Days",
  4: "120 Days",
};
const minimumOrderObj = {
  1: "Case Pack",
  2: "Units",
};

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (text) => <span className={classes.itemHeading}>{text}</span>,
  },
  {
    title: "",
    dataIndex: "data",
    key: "data",
    align: "right",
    render: (text) => {
      if (Array.isArray(text)) {
        return (
          <>
            {text.map((item, index) =>
              Array.isArray(item) ? (
                <div key={index}>
                  {item.map((innerItem, innerIndex) => (
                    <Button
                      style={{ marginRight: "5px" }}
                      key={innerIndex}
                      onClick={() => handleDownload(innerItem)}
                    >
                      Download <DownloadOutlined />
                    </Button>
                  ))}
                </div>
              ) : (
                <>
                  {item?.name && (
                    <span style={{ marginRight: "8px" }}>{item?.name}</span>
                  )}
                  <Button
                    style={{ marginRight: "5px" }}
                    key={index}
                    onClick={() =>
                      handleDownload(item?.logoPath ? item?.logoPath : item)
                    }
                  >
                    Download <DownloadOutlined />
                  </Button>
                </>
              ),
            )}
          </>
        );
      } else {
        return <span className={classes.itemData}>{text}</span>;
      }
    },
  },
];

const CategoryProductInfoTab = (props) => {
  const navigate = useNavigate();
  const [infoData, setInfoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const userObj = useSelector((state) => state.user.data);

  const dataObj = {
    averageTimeRequire: {
      name: "Average Print, Pack, and Ship Time requirement after receiving PO",
      required: [2, 3],
    },
    edi: {
      name: "EDI (Electronic Data Interchange) Compliance Required",
      required: [2, 3],
    },
    minimumOrder: {
      name: "Category Minimum Order Quantity Requirement",
      required: [2, 3],
    },
    pickupDetails: { name: "Pickup Details", required: [3] },
    pickupPoints: {
      name: userObj.user_type === "RETAILER" ? "Shipping" : "Pickup Points",
      required: [3],
    },
    pickupRequired: { name: "Pickup Required", required: [2, 3] },
    workWithAllowance: {
      name: "Work with allowance program",
      required: [2, 3],
    },
    workWithDeadnet: { name: "Work with deadnet program", required: [2, 3] },
    otherLink: { name: "Other Link", required: [3] },
    certificateOfInsurancePath: {
      name: "Certificate of Insurance",
      required: [3],
    },
    factoryAuditReportPath: { name: "Audit Report", required: [3] },
    ndapath: { name: "NDA", required: [3] },
    otherCertificatePath: { name: "Other Certificates", required: [3] },
    privateBrand: { name: "Private Brand", required: [3] },
  };

  async function fetchData() {
    try {
      const res = await getCategoryAndProductInformation({
        userId: params.supplierId,
      });
      // if (res && res.data) {
      const data = res.data || {};
      console.log("Datasds: ", data);

      const orderedKeys = Object.keys(dataObj);
      const userPlan = parseInt(localStorage.getItem("subscription"), 10) || 1;

      const infoData = orderedKeys.map((key) => {
        const value = data[key];
        const item = dataObj[key];

        let mappedName = item?.name;
        console.log("mappedName1: ", mappedName);
        let displayValue =
          value === undefined || value === null
            ? "Field not entered by profile owner"
            : value;

        if (key === "averageTimeRequire") {
          displayValue =
            avgTimeRequired[value] || "Field not entered by profile owner";
        } else if (key === "certificateOfInsurancePath") {
          // if (displayValue) {
          //   return [];
          // }

          displayValue = [value];
          //return [];
        } else if (key === "edi") {
          if (displayValue) {
            displayValue = "Yes";
          } else {
            displayValue = "No";
          }
        } else if (key === "documentAndForumPath") {
          if (displayValue) {
            return [];
          }
        } else if (key === "factoryAuditReportPath") {
          // if (displayValue) {
          //   return [];
          // }
          displayValue = [value];
        } else if (key === "minimumOrder") {
          displayValue =
            minimumOrderObj[value] || "Field not entered by profile owner";
        } else if (key === "ndapath") {
          // if (displayValue) {
          //   return [];
          // }

          displayValue = [value];
        } else if (key === "otherCertificatePath") {
          // if (displayValue) {
          //   return [];
          // }
          displayValue = [value];
        } else if (key === "pickupRequired") {
          if (displayValue) {
            displayValue = "Yes";
          } else {
            displayValue = "No";
          }
        } else if (key === "workWithAllowance") {
          if (displayValue) {
            displayValue = "Yes";
          } else {
            displayValue = "No";
          }
        } else if (key === "workWithDeadnet") {
          if (displayValue) {
            displayValue = "Yes";
          } else {
            displayValue = "No";
          }
        } else if (key === "privateBrand") {
          // if (value.length == 0) {
          //   return [];
          // }
          displayValue = [value];
        }

        const isAllowed = item.required.includes(userPlan);
        console.log("mappedName2: ", mappedName);
        return {
          name: mappedName,
          data: isAllowed ? displayValue : null,
          isAllowed,
        };
      });
      console.log("infoData: ", infoData);

      setInfoData(infoData);
      // }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [props.currentPlan]);

  const SkellotonCtrl = (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );

  const tableData = infoData.map((item) => {
    if (!item.isAllowed) {
      return {
        name: item.name,
        data: (
          <span>
            <p>Please Upgrade Plan to View this Information</p>
            <Button
              type="primary"
              onClick={() => {
                navigate("/app/settings");
              }}
            >
              Subscribe Now
            </Button>
          </span>
        ),
      };
    }
    return {
      name: item.name,
      data: item.data,
    };
  });

  return loading ? (
    SkellotonCtrl
  ) : (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      showHeader={false}
      bordered={false}
    />
  );
};

export default CategoryProductInfoTab;
